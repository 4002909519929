import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
Vue.component("translation", {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = "es";
    },
  },
});

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    themes: {
      light: {
        primary: "#f6cdd7",
        second: "#53575a",
        third: "#c0a4b7",
        four: "#a2a9ad",
        fifth: "#c5c3d1",
        sixth: "#a09abd",
        white: "#fff",
        error: "#ff7782",
        default: "#696969",
        dark: "#363949",
        darkLight: "#53575a",
        iconBar: "#c5c3d1",
        iconBarDark: "#a09abd",
        headerAdmin: "#f6f7ff",
        headerMobile: "#ffffff",
      },
      dark: {
        primary: "#ff4584",
        dark: "#363949",
      },
    },
  },
});
