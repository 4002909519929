import Vue from "vue";
import * as moment from "moment";
moment.locale("es");

const REFERENCE = moment();
const TODAY = REFERENCE.clone().startOf("day");
const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
const A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");

const isToday = (momentDate) => {
  return momentDate.isSame(TODAY, "d");
};

const isYesterday = (momentDate) => {
  return momentDate.isSame(YESTERDAY, "d");
};

const isWithinAWeek = (momentDate) => {
  return momentDate.isAfter(A_WEEK_OLD);
};

const isTwoWeeksOrMore = (momentDate) => {
  return !this.isWithinAWeek(momentDate);
};

Vue.filter("unitMile", (value) => {
  if (!value) {
    return;
  }

  let mil = value.replace(/\./g, "");
  if (!isNaN(mil)) {
    mil = mil
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
    /* eslint-disable */
    mil = mil.split("").reverse().join("").replace(/^[\.]/, "");
  }
  return mil;
});

Vue.filter("urlImage", (value) => {
  if (value) {
    return `${process.env.VUE_APP_PHOTO_API}${value}`;
  }
  return "img/default-photo.png";
});

Vue.filter("imageProfile", (value) => {
  if (value && value.indexOf("/images") !== -1) {
    return `${process.env.VUE_APP_PHOTO_API}${value}`;
  } else if (value && value.indexOf(";base64,") !== -1) {
    return value;
  }
  return "img/profile/profile.png";
});

Vue.filter("imageDefault", (value) => {
  if (value && value.indexOf("/images") !== -1) {
    return `${process.env.VUE_APP_PHOTO_API}${value}`;
  } else if (value && value.indexOf(";base64,") !== -1) {
    return value;
  }
  return "img/default-photo.png";
});

Vue.filter("date", (value) => {
  return moment(value).format("DD/MM/YYYY");
});

Vue.filter("dateTime", (value) => {
  return moment(value).format("DD/MM/YYYY  hh:mm a");
});

Vue.filter("formatDolar", (value) => {
  const format = new Intl.NumberFormat("en-EN", {
    minimumFractionDigits: 2,
  }).format(value);
  return format;
});

Vue.filter("formatBs", (value) => {
  let result = parseFloat(value.toString());
  result = result.toFixed(2);
  const formatBs = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
  }).format(result);
  return formatBs;
});

Vue.filter("status", (value) => {
  let status = null;
  switch (value) {
    case 1:
      status = "Cuotas";
      break;
    case 2:
      status = "Nuevo pedido";
      break;
    case 3:
      status = "Verificar";
      break;
    case 4:
      status = "Completado";
      break;
  }

  return status;
});

Vue.filter("priceTotal", (value) => {
  let total = 0;
  total = value.count * value.price;

  const format = new Intl.NumberFormat("en-EN", {
    minimumFractionDigits: 2,
  }).format(total);
  return format;
});

Vue.filter("notificationDay", (value) => {
  const day = moment.utc().diff(value, "days");
  const local = moment.utc(value).toDate();
  switch (true) {
    case day >= 30 && day < 60:
      return "Mes pasado";
    case day >= 60:
      return moment.utc(value).format("DD [de] MMMM YYYY");
    case isToday(moment(local).local()):
      // return moment.utc(value, 'YYYY-MM-DD HH:mm:ss').fromNow();
      return "Hoy";
    case isYesterday(moment(local).local()):
      return "Ayer";
    case isWithinAWeek(moment(local).local()):
      return "Esta semana";
    case isTwoWeeksOrMore(moment(local).local()):
      return "Hace semanas";
    default:
      return moment.utc(value, "YYYY-MM-DD HH:mm:ss").fromNow();
  }
});

Vue.filter("relativeDay", (value) => {
  const result = moment.utc().diff(value, "days");
  return result >= 30
    ? moment.utc(value).format("DD [de] MMMM YY")
    : moment.utc(value, "YYYY-MM-DD HH:mm:ss").fromNow();
});
