import { db } from "./db.js";

const NotifyStorage = () => {
  const notificationAdd = async (data) => {
    await db.notify.put(data);
  };

  const notificationAll = async () => {
    return await db.notify.orderBy("createdAt").reverse().toArray();
  };

  const notificationDeleteById = async (id) => {
    await db.notify.delete(id);
  };

  const notificationClear = async () => {
    await db.notify.clear();
  };

  return {
    notificationAdd,
    notificationAll,
    notificationDeleteById,
    notificationClear,
  };
};

export default NotifyStorage;
