import {
  GET_TYPES,
  SET_TYPES,
  CLEAR_TYPES,
  LIST_TYPES,
  COUNT_TYPES,
} from "./actions-types";
import UserStorage from "../core/storage/user-storage";
import SettingStorage from "../core/storage/setting-storage";
import ClientStorage from "../core/storage/client-storage";
import OrderStorage from "../core/storage/order-storage";
import ProductStorage from "../core/storage/product-storage";
import MarkStorage from "../core/storage/mark-storage";
import ClientService from "../core/services/client-services";
import NotifyStorage from "../core/storage/notification-storage";

export default {
  async getUserLogin({ commit }) {
    const { userId } = UserStorage();
    const user = await userId();
    commit(GET_TYPES.GET_USER_ID, { user });
  },
  async getSetting({ commit }) {
    const { settingLast } = SettingStorage();
    const setting = await settingLast();
    commit(GET_TYPES.GET_SETTINGS, { setting });
  },
  async setCart({ commit }, { cart }) {
    commit(GET_TYPES.GET_CART, { cart });
  },
  getListClientService({ commit }) {
    const { getClientFromSellerId } = ClientService();
    const { clientAdd } = ClientStorage();
    getClientFromSellerId().then((resp) => {
      const response = resp.data;
      if (response.code === 200) {
        response.data.map((item) => {
          clientAdd(item);
        });
        commit(LIST_TYPES.LIST_CLIENT, { clients: response.data });
      }
    });
  },
  async getListClients({ commit }) {
    const { clientAll } = ClientStorage();
    const clients = await clientAll();
    commit(LIST_TYPES.LIST_CLIENT, { clients });
  },
  async getListOrders({ commit }) {
    const { orderAll } = OrderStorage();
    const orders = await orderAll();
    commit(LIST_TYPES.LIST_ORDER, { orders });
  },
  async getListProducts({ commit, state }) {
    const { productAll } = ProductStorage();
    const { markAll } = MarkStorage();

    const mark = await markAll();
    const products = await productAll();

    let listProducts = {};
    mark.map((item) => {
      const prod = products.filter((element) => element.id === item.id);

      if (prod.length == 0) {
        return;
      }

      listProducts[prod[0].name] = prod[0].data;
      if (Object.keys(state.products).length > 1) {
        const newList = prod[0].data.map((product) => {
          const data = state.products[prod[0].name].filter(
            (option) =>
              option.id === product.id && product.status === 1 && option.count
          );
          if (data[0]) {
            data[0]["stock"] = product.stock;
            return data[0];
          } else {
            return product;
          }
        });
        listProducts[prod[0].name] = newList;
      }
    });
    commit(LIST_TYPES.LIST_PRODUCT, { products: listProducts });
  },
  async setProduct({ commit }, { products, name }) {
    commit(SET_TYPES.SET_PRODUCT, { products, name });
  },
  setSelectOrdenItem({ commit }, { orden }) {
    commit(SET_TYPES.SET_SELECT_ORDEN, { orden });
  },
  async getSelectOrdenItemDefault({ commit }, { orden }) {
    const { orderAll } = OrderStorage();
    const orders = await orderAll();
    const item = orders.filter((item) => item.id === orden.id)[0];
    commit(SET_TYPES.SET_SELECT_ORDEN, { orden: item });
  },
  clearProducts({ commit }, { name }) {
    commit(CLEAR_TYPES.CLEAR_PRODUCT, { name });
  },
  getCountNotify({ commit }, { count }) {
    commit(COUNT_TYPES.COUNT_NOTIFY, { count });
  },
  async getNotify({ commit }) {
    const { notificationAll } = NotifyStorage();
    const notify = await notificationAll();
    commit(LIST_TYPES.LIST_NOTIFY, { notify });
  },
};
