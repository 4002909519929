import Dexie from "dexie";

export const db = new Dexie("cosmetic");
db.version(1).stores({
  config: "++id, token",
  user: "++id",
  admin: "++id, createdAt",
  seller: "++id, createdAt",
  client: "++id, createdAt",
  mark: "++id, createdAt",
  product: "++id, createdAt",
  order: "++id, createdAt",
  report: "++id, createdAt",
  notify: "++id, createdAt",
  setting: "++id",
  darkMode: "++id",
  orderNew: "++id, createdAt",
  orderCheck: "++id, createdAt",
  orderComplete: "++id, createdAt",
  dashboard: "++id",
});
