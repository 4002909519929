const GET_TYPES = {
  GET_USER_ID: "GET_USER_ID",
  GET_SETTINGS: "GET_SETTINGS",
  GET_CART: "GET_CART",
};
export { GET_TYPES };

const SET_TYPES = {
  SET_PRODUCT: "SET_PRODUCT",
  SET_SELECT_ORDEN: "SET_SELECT_ORDEN",
};
export { SET_TYPES };

const CLEAR_TYPES = {
  CLEAR_PRODUCT: "CLEAR_PRODUCT",
};
export { CLEAR_TYPES };

const LIST_TYPES = {
  LIST_CLIENT: "LIST_CLIENT",
  LIST_ORDER: "LIST_ORDER",
  LIST_PRODUCT: "LIST_PRODUCT",
  LIST_NOTIFY: "LIST_NOTIFY",
};
export { LIST_TYPES };

const COUNT_TYPES = {
  COUNT_NOTIFY: "COUNT_NOTIFY",
};
export { COUNT_TYPES };
