import { db } from "./db.js";

const OrderStorage = () => {
  const orderAdd = async (data) => {
    await db.order.put(data);
  };

  const orderAll = async () => {
    return await db.order.orderBy("createdAt").reverse().toArray();
  };

  const orderDeleteById = async (id) => {
    await db.order.delete(id);
  };

  const orderClear = async () => {
    await db.order.clear();
  };

  return {
    orderAdd,
    orderAll,
    orderDeleteById,
    orderClear,
  };
};

export default OrderStorage;
