import Vue from "vue";
import VueRouter from "vue-router";
import ConfigStorage from "../core/storage/config-storage.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/login.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../pages/home/home.vue"),
    beforeEnter: async (to, from, next) => {
      const { getConfig } = ConfigStorage();
      const config = await getConfig();
      const isAuthenticated = config && config.token ? true : false;
      if (!isAuthenticated) {
        next({ name: "login" });
      } else {
        next();
      }
    },
    children: [
      {
        path: "admin",
        name: "admin",
        component: () => import("../pages/home/admin/admin.vue"),
        children: [
          {
            path: "dashboard",
            name: "dashboard",
            component: () =>
              import("../pages/home/admin/dashboard/dashboard.vue"),
          },
          {
            path: "category",
            name: "category",
            component: () =>
              import("../pages/home/admin/category/category.vue"),
          },
          {
            path: "mark",
            name: "mark",
            component: () => import("../pages/home/admin/mark/mark.vue"),
          },
          {
            path: "product",
            name: "product",
            component: () => import("../pages/home/admin/product/product.vue"),
          },
          {
            path: "order",
            name: "order",
            component: () => import("../pages/home/admin/order/order.vue"),
          },
          {
            path: "report",
            name: "report",
            component: () => import("../pages/home/admin/report/report.vue"),
          },
          {
            path: "client",
            name: "client",
            component: () => import("../pages/home/admin/client/client.vue"),
          },
          {
            path: "seller",
            name: "seller",
            component: () => import("../pages/home/admin/seller/seller.vue"),
          },
          {
            path: "user",
            name: "user",
            component: () => import("../pages/home/admin/admin/admin.vue"),
          },
          {
            path: "setting",
            name: "setting",
            component: () => import("../pages/home/admin/setting/setting.vue"),
          },
          {
            path: "notification",
            name: "notification",
            component: () =>
              import("../pages/home/admin/notification/notification.vue"),
          },
        ],
      },
      {
        path: "mobile",
        component: () => import("../pages/home/mobile/mobile.vue"),
        children: [
          {
            path: "mark",
            component: () => import("../pages/home/mobile/mark/mark.vue"),
          },
          {
            path: "order",
            component: () => import("../pages/home/mobile/order/order.vue"),
          },
          {
            path: "cart",
            component: () => import("../pages/home/mobile/cart/cart.vue"),
          },
          {
            path: "notification",
            component: () =>
              import("../pages/home/mobile/notification/notification.vue"),
          },
          {
            path: "convert",
            component: () => import("../pages/home/mobile/convert/convert.vue"),
          },
          {
            path: "client",
            component: () => import("../pages/home/mobile/client/client.vue"),
          },
          {
            path: "profile",
            component: () => import("../pages/home/mobile/profile/profile.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  history: "history",
  linkActiveClass: "active",
  routes,
  mode: "hash",
});

export default router;
