import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import interceptorsSetup from "./core/services/interceptor.js";
import "./shared/filters/useFilter.js";
import Toast, { POSITION } from "vue-toastification";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";

import "./assets/scss/app.scss";
import "vue-toastification/dist/index.css";
import "./registerServiceWorker";

interceptorsSetup();

const socket = io(process.env.VUE_APP_WEB_SOCKET);
const options = {
  position: POSITION.TOP_RIGHT,
  timeout: 4000,
};

Vue.config.productionTip = false;
Vue.use(Toast, options);
Vue.use(VueSocketIOExt, socket);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
