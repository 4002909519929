import { db } from "./db.js";

const ClientStorage = () => {
  const clientAdd = async (data) => {
    await db.client.put(data);
  };

  const clientAll = async () => {
    return await db.client.orderBy("createdAt").reverse().toArray();
  };

  const clientDeleteById = async (id) => {
    await db.client.delete(id);
  };

  const clientClear = async () => {
    await db.client.clear();
  };

  return {
    clientAdd,
    clientAll,
    clientDeleteById,
    clientClear,
  };
};

export default ClientStorage;
