import { db } from "./db.js";

const SettingStorage = () => {
  const settingAdd = async (data) => {
    await db.setting.put(data);
  };

  const settingLast = async () => {
    return await db.setting.orderBy("id").last();
  };

  const settingClear = async () => {
    await db.setting.clear();
  };

  return {
    settingAdd,
    settingLast,
    settingClear,
  };
};

export default SettingStorage;
