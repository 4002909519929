export default {
  fetchingData: true,
  error: null,

  countNotify: 0,

  user: {},
  setting: {},
  cart: [],
  clients: [],
  orders: [],
  products: [],
  selectOrden: {},
  notify: [],
};
