<template>
  <v-app id="app">
    <div v-if="this.showSplash" class="splash">
      <img src="img/splash.gif" />
    </div>
    <div v-if="!this.showSplash">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import { timer } from "rxjs";

export default {
  name: "App",
  data: () => ({
    showSplash: false,
  }),
  mounted() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.showSplash = true;
      timer(4000).subscribe(() => (this.showSplash = false));
    }
  },
};
</script>

<style lang="scss">
.splash {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 1;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100vh;
  }
}
</style>
