import { db } from "./db.js";

const MarkStorage = () => {
  const markAdd = async (data) => {
    await db.mark.put(data);
  };

  const markAll = async () => {
    return await db.mark.orderBy("createdAt").toArray();
  };

  const markDeleteById = async (id) => {
    await db.mark.delete(id);
  };

  const markClear = async () => {
    await db.mark.clear();
  };

  return {
    markAdd,
    markAll,
    markDeleteById,
    markClear,
  };
};

export default MarkStorage;
