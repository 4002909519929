import axios from "axios";

const ClientServices = () => {
  const getClientAll = async () => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/clients`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const getClientFromSellerId = async () => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/clients/seller`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const saveClient = async (data) => {
    return await axios
      .post(`${process.env.VUE_APP_ROOT_API}/clients`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const updateClient = async (id, data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/clients/${id}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const deleteClientId = async (id) => {
    return await axios
      .delete(`${process.env.VUE_APP_ROOT_API}/clients/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  return {
    getClientAll,
    getClientFromSellerId,
    saveClient,
    updateClient,
    deleteClientId,
  };
};

export default ClientServices;
