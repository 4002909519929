import axios from "axios";
import ConfigStorage from "../storage/config-storage";
import GlobalServise from "../services/globals-services";

export default function interceptorsSetup() {
  axios.interceptors.request.use(
    async (config) => {
      const { getConfig } = ConfigStorage();
      const token = await getConfig();
      if (token && token.token) {
        config.headers.Authorization = `Bearer ${token.token}`;
      }
      config.headers.ContentType = "application/json";
      config.headers.Accept = "application/json";
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },
    (error) => {
      if (error.response.status) {
        const { setLogout } = GlobalServise();
        switch (error.response.status) {
          case 401:
            setLogout({ code: 401 });
            break;
        }
        return Promise.reject(error.response);
      }
    }
  );
}
