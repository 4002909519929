import { Subject } from "rxjs";

const _auth = new Subject();

const GlobalServise = () => {
  const setLogout = (value) => {
    _auth.next(value);
  };

  const onLogout = () => {
    return _auth.asObservable();
  };

  return {
    setLogout,
    onLogout,
  };
};

export default GlobalServise;
