import { db } from "./db.js";

const UserStorage = () => {
  const addUser = async (data) => {
    await db.user.put(data);
  };

  const userId = async () => {
    return await db.user.orderBy("id").last();
  };

  const clearUser = async () => {
    await db.user.clear();
  };

  return {
    addUser,
    userId,
    clearUser,
  };
};

export default UserStorage;
