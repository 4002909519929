import {
  GET_TYPES,
  SET_TYPES,
  CLEAR_TYPES,
  LIST_TYPES,
  COUNT_TYPES,
} from "./actions-types";

export default {
  [GET_TYPES.GET_USER_ID](state, { user }) {
    state.user = user;
  },
  [GET_TYPES.GET_SETTINGS](state, { setting }) {
    state.setting = setting;
  },
  [GET_TYPES.GET_CART](state, { cart }) {
    state.cart = cart;
  },
  [LIST_TYPES.LIST_CLIENT](state, { clients }) {
    state.clients = clients;
  },
  [LIST_TYPES.LIST_ORDER](state, { orders }) {
    state.orders = orders;
  },
  [LIST_TYPES.LIST_PRODUCT](state, { products }) {
    state.products = products;
  },
  [SET_TYPES.SET_PRODUCT](state, { products, name }) {
    state.products = { ...state.products, [name]: products };
  },
  [SET_TYPES.SET_SELECT_ORDEN](state, { orden }) {
    state.selectOrden = orden;
  },
  [CLEAR_TYPES.CLEAR_PRODUCT](state, { name }) {
    state.products = { ...state.products, [name]: [] };
  },
  [COUNT_TYPES.COUNT_NOTIFY](state, { count }) {
    state.countNotify = count;
  },
  [LIST_TYPES.LIST_NOTIFY](state, { notify }) {
    state.notify = notify;
  },
};
