import { db } from "./db.js";

const ProductStorage = () => {
  const productAdd = async (data) => {
    await db.product.put(data);
  };

  const productAll = async () => {
    return await db.product.orderBy("createdAt").reverse().toArray();
  };

  const productDeleteById = async (id) => {
    await db.product.delete(id);
  };

  const productClear = async () => {
    await db.product.clear();
  };

  return {
    productAdd,
    productAll,
    productDeleteById,
    productClear,
  };
};

export default ProductStorage;
