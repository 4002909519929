import { db } from "./db.js";

const ConfigStorage = () => {
  const addConfig = async (token) => {
    await db.config.put({
      token,
    });
  };

  const getConfig = async () => {
    return await db.config.orderBy("id").last();
  };

  const clearConfig = async () => {
    await db.config.clear();
  };

  const addDarkMode = async (darkMode) => {
    await db.darkMode.put({
      id: 1,
      darkMode,
    });
  };

  const getDarkMode = async () => {
    return await db.darkMode.orderBy("id").last();
  };

  const clearDarkMode = async () => {
    await db.darkMode.clear();
  };

  return {
    addConfig,
    getConfig,
    clearConfig,
    addDarkMode,
    getDarkMode,
    clearDarkMode,
  };
};

export default ConfigStorage;
